// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import React from 'react';
import { _AUTH } from '../../../constants/_auth';

function TableScheduleSubject(props) {
    let role = localStorage.getItem(_AUTH.ROLE) !== null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';
    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên lịch học
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Giảng viên
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày bắt đầu
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày kết thúc
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số buổi học dự kiến
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số buổi học đã tạo
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số buổi học đã dạy
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tổng số sinh viên
                </th>
                {role ? <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Hành động
                </th> : ''}
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                    onClick={(e) => {
                        props.clicked(e, item.id);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-medium'>
                            {item.name}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                            <div>
                                <div className='text-sm text-gray-900'>
                                    {item['teacher'].fullName}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/YYYY'>
                                    {item.startDate}
                                </Moment>
                            ) : (
                                <div>{item.startDate}</div>
                            )}


                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/YYYY'>
                                    {item.endDate}
                                </Moment>
                            ) : (
                                <div>{item.endDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-center text-sm'>
                        {item.totalSessions}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-center text-sm'>
                        {item.numberOfSubjectSchedule}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-center text-sm'>
                        {item.numberOfTaughtSubjectSchedule}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-center text-sm'>
                        {item.numberOfStudent}
                    </td>
                    {role ? <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            <div
                                className='text-green-700 hover:text-blue-800 font-semibold cursor-pointer'>
                                <a href={`/chi-tiet-lop-mon-hoc/` + item.id}>

                                    <i className='far fa-eye mr-2' />
                                    Xem chi tiết lớp môn học
                                </a>
                            </div>
                            <span className='mx-2'>/</span>
                            <div
                                className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.updateClicked(e, item);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Chỉnh sửa
                            </div>
                            <span className='mx-2'>/</span>
                            <div
                                className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.deleteClicked(e, item.id);
                                }}>
                                <i className='far fa-trash-alt mr-2' />
                                Xóa
                            </div>
                        </div>
                    </td> : ''}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableScheduleSubject;
