import Auth from 'pages/Auth/Auth';
import Faculties from 'pages/Faculties/Faculties__List/Faculties__List';
import Classes from 'pages/Class/Class__List/Class__List';
import ClassCreate from 'pages/Class/Class__Create/Class__Create';
import Students from 'pages/Student/Student__List/Student__List';
import StudentCreate from 'pages/Student/Student__Create/Student__Create';
import Users from 'pages/User/User__List/User__List';
import UserCreate from 'pages/User/User__Create/User__Create';
import ChangePassword from 'pages/Auth/ChangePassword/ChangePassword';
import UpdatePassword from 'pages/UpdatePassword/UpdatePassword';
import Schedule__Faculties from 'pages/Schedule/Schedule__Faculties/Schedule__Faculties';
import Subject__List from 'pages/Subject/Subject__List/Subject__List';
import Schedule__Teaching from 'pages/Schedule/Schedule__Teaching/Schedule__Teaching';
import Schedule__Subject from 'pages/Schedule/Schedule__Subject/Schedule__Subject';
import { __version } from 'pages/__version';
import Success from '../components/Auth/Success/Success';
import Schedule__Subject__Teaching from 'pages/Schedule/Schedule__Subject/Schedule__Subject__Teaching';
import AuditLog from '../pages/AuditLog/Audit_Log';
import StudentNotification from '../pages/Student/Student__Notification/Student__Notification';
import Exam_List from '../pages/Exam/Exam_List';
import ScheduleTeachingDetails from '../pages/Schedule/Schedule__Teaching_Details/Schedule__Teaching__Details';
import Attendance from '../pages/Attendance/Attendance';
import Form_Request__List from '../pages/FormRequest/Form_Request__List/Form_Request__List';
import Form_Request__View from '../pages/FormRequest/Form_Request_View/Form_Request_View';
import OnlineExam__List from '../pages/OnlineExam/OnlineExam__List/OnlineExam__List';
import QuestionSuite__List from '../pages/QuestionSuite/QuestionSuite__List';
import Subject__Class from '../pages/Subject/Subject__Class/Subject__Class';
import Student__Manager from '../pages/StudentManager/Student__Manager';

export const routes = [
    {
        path: '/auth',
        component: Auth,
        exact: true
    },
    {
        path: '/__version',
        component: __version,
        exact: true
    },
    {
        path: '/ResetPassword',
        component: ChangePassword,
        exact: true
    },
    {
        path: '/',
        component: Faculties,
        exact: true
    },
    {
        path: '/lop-hoc',
        component: Classes,
        exact: true
    },
    {
        path: '/lop-hoc/them-moi',
        component: ClassCreate,
        exact: true
    },
    {
        path: '/lop-hoc/:id',
        component: ClassCreate,
        exact: true
    },
    {
        path: '/sinh-vien',
        component: Students,
        exact: true
    },
    {
        path: '/sinh-vien/them-moi',
        component: StudentCreate,
        exact: true
    },
    {
        path: '/sinh-vien/:id',
        component: StudentCreate,
        exact: true
    }, {
        path: '/thong-bao-lop-hoc/:id',
        component: StudentNotification,
        exact: true
    },
    {
        path: '/quan-ly-chung',
        component: Users,
        exact: true
    },
    {
        path: '/quan-ly-chung/them-moi',
        component: UserCreate,
        exact: true
    },
    {
        path: '/quan-ly-chung/:id',
        component: UserCreate,
        exact: true
    },
    {
        path: '/thoi-khoa-bieu',
        component: Subject__List,
        exact: true
    },
    {
        path: '/thoi-khoa-bieu/lich-hoc/:id',
        component: Schedule__Teaching,
        exact: true
    },
    {
        path: '/thoi-khoa-bieu/:fId/:cId',
        component: Schedule__Faculties,
        exact: true
    },
    {
        path: '/doi-mat-khau',
        component: UpdatePassword,
        exact: true
    },
    {
        path: '/thanh-cong',
        component: Success,
        exact: true
    },
    {
        path: '/lich-hoc-mon',
        component: Schedule__Subject,
        exact: true
    },
    {
        path: '/lich-hoc-mon-chitiet/:idSubjectTeaching',
        component: Schedule__Subject__Teaching,
        exact: true
    },
    {
        path: '/chi-tiet-lop-mon-hoc/:idSubjectTeaching',
        component: ScheduleTeachingDetails,
        exact: true
    },
    {
        path: '/lich-thi-chi-tiet/:id',
        component: Exam_List,
        exact: true
    },
    {
        path: '/audit-log',
        component: AuditLog,
        exact: true
    },
    {
        path: '/diem-danh',
        component: Attendance,
        exact: true
    },
    {
        path: '/bieu-mau-xac-nhan',
        component: Form_Request__List,
        exact: true
    },
    {
        path: '/bieu-mau-xac-nhan/:id',
        component: Form_Request__View,
        exact: true
    },
    {
        path: '/quan-ly-bo-de/:idSubjectTeaching',
        component: OnlineExam__List,
        exact: true
    },
    {
        path: '/bo-cau-hoi/:idQuestionSuite',
        component: QuestionSuite__List,
        exact: true
    },
    {
        path: '/thoi-khoa-bieu-lop',
        component: Subject__Class,
        exact: true
    },
    {
        path: '/thoi-khoa-bieu-lop/:fId/:cId',
        component: Subject__Class,
        exact: true
    },
    {
        path: '/quan-ly-sinh-vien',
        component: Student__Manager,
        exact: true
    },
];
