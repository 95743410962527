import Button from 'components/Elements/Button/Button';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import './faculties__list.scss';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { $api } from 'services/service';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { setAlert, setLoading } from 'redux/authSlice';

const fileDownload = require('js-file-download');

function Faculties() {
	const [faculties, setFaculties] = useState([]);
	const [facultiesName, setFacultiesName] = useState('');
	const [facultiesCode, setFacultiesCode] = useState('');
	const [facultiesColor, setFacultiesColor] = useState('#1E3A8A');
	const [modalShow, setModelShow] = useState(false);
	const [changes, setChanges] = useState(false);
	const [updateMode, setUpdateMode] = useState(null);
	const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [idDelete, setIdDelete] = useState(null);
	const [noti, setNoti] = useState({ message: '', enforceRead: false });
	const [confirmDialogAddNotification, setConfirmDialogAddNotification] = useState({
		isOpen: false,
		title: '',
		subTitle: ''
	});
	const [modalShowAddNotification, setModalShowAddNotification] = useState(false);

	let history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		async function getFaculties() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.faculties.getPaging({});
				if (res && res.result) {
					setFaculties(res.result.items);
				}
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}
		getFaculties();
	}, [changes]);

	async function createFaculties() {
		try {
			if (facultiesName.length <= 0) {
				show_ErrMsg('Vui lòng nhập tên khoa');
				return;
			}
			if (facultiesCode.length <= 0) {
				show_ErrMsg('Vui lòng nhập mã khoa');
				return;
			}

			dispatch(setLoading(true));
			const res: any = await $api.faculties.createFaculties({
				name: facultiesName,
				colorCode: facultiesColor,
				code: facultiesCode,
			});
			if (res) {
				setFacultiesName('');
				setFacultiesCode('');
				setChanges(!changes);
				dispatch(
					setAlert({
						type: 'success',
						title: `Tạo thành công ${facultiesName}`,
					})
				);
			}
			dispatch(setLoading(false));
			setModelShow(false);
		} catch (e) {
			dispatch(setLoading(false));
			// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function goClass(id) {
		history.push(`/lop-hoc?f=${id}`);
	}

	async function openUpdateFaculties(e, id, name, colorCode, code) {
		e.stopPropagation();
		try {
			setFacultiesName(name);
			setFacultiesColor(colorCode);
			setFacultiesCode(code);
			setUpdateMode(id);
			setModelShow(true);
		} catch (e) { }
	}

	async function updateFaculties(id) {
		try {
			if (facultiesName.length <= 0) {
				show_ErrMsg('Vui lòng nhập tên khoa');
				return;
			}
			if (facultiesCode.length <= 0) {
				show_ErrMsg('Vui lòng nhập mã khoa');
				return;
			}

			dispatch(setLoading(true));
			const res: any = await $api.faculties.updateFaculties({
				id: id,
				name: facultiesName,
				colorCode: facultiesColor,
				code: facultiesCode,
			});
			if (res) {
				dispatch(
					setAlert({
						type: 'success',
						title: `Cập nhật thành công ${facultiesName}`,
					})
				);
				setFacultiesName('');
				setFacultiesCode('');
				setChanges(!changes);
			}
			dispatch(setLoading(false));
			setModelShow(false);
			setUpdateMode(null);
		} catch (e) {
			dispatch(setLoading(false));
			// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function deleteFaculties(e, id) {
		e.stopPropagation();
		setConfirmDialog({
			isOpen: true
			, title: 'Thông báo'
			, subTitle: 'Bạn có chắc muốn xóa ?'
		})
		setIdDelete(id);
	}

	async function deleteItem() {
		setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
		try {
			dispatch(setLoading(true));
			const res: any = await $api.faculties.deleteFaculties(idDelete);
			if (res) {
				setFacultiesName('');
				setFacultiesCode('');
				setChanges(!changes);
				dispatch(
					setAlert({
						type: 'success',
						title: `Xóa thành công`,
					})
				);
			}
			dispatch(setLoading(false));
			setModelShow(false);
		} catch (e) {
			dispatch(setLoading(false));
			// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	// file
	async function importFaculties() {
		// @ts-ignore
		document.getElementById('faculties-import').click();
	}

	async function uploadFile(event) {
		const formData = new FormData();
		formData.append('formFile', event.target.files[0]);
		try {
			dispatch(setLoading(true));
			const res: any = await $api.faculties.importFaculties(formData);
			if (res && res.message) {
				setChanges(!changes);
				dispatch(setLoading(false));
				return dispatch(
					setAlert({
						title: res.message,
					})
				);
			}
			if (res && res.errors) {
				dispatch(setLoading(false));
				show_ErrMsg(res?.errors?.exceptionMessage ? res?.errors?.exceptionMessage : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
				return;
			}
		} catch (e) {
			let err = '';
			if (e.response && e.response.data && e.response.data.errors) {
				if (e.response.data.errors.rowErrors) {
					for (var er of e.response.data.errors.rowErrors['details']) {
						err += er + '\r\n';
					}
				} else {
					err = e.response.data.errors.exceptionMessage;
				}
			}
			dispatch(setLoading(false));
			show_ErrMsg(err);
			return;
		} finally {
			event.target.value = '';
		}
	}

	async function downloadTemplate() {
		const res: any = await $api.faculties.downloadTemplate();
		fileDownload(res, 'ban-mau-danh-sach-phong-ban.xlsx');
	}

	async function exportFaculties() {
		const res: any = await $api.faculties.exportFaculties();
		fileDownload(res, 'danh-sach-phong-ban.xlsx');
	}
	async function createNotification() {
		if (noti.message.length) {
			setConfirmDialogAddNotification({
				isOpen: true,
				title: 'Xác nhận',
				subTitle: 'Bạn muốn gửi thông báo: "' + noti.message + '" đến toàn bộ sinh viên?'
			});
		} else {
			setErrDialog({
				isOpen: true,
				subTitle: 'Bạn chưa nhập nội dung thông báo!'
			});
		}
	}
	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}
	async function addNotification() {
		setConfirmDialogAddNotification({ isOpen: false, title: '', subTitle: '' });
		try {
			dispatch(setLoading(true));
			const res: any = await $api.userAnnouncement.createNotification({
				'message': noti.message,
				'enforceRead': noti.enforceRead
			});
			if (res) {
				dispatch(
					setAlert({
						title: res.message
					})
				);
				setChanges(!changes);
				setModalShowAddNotification(false);
				setNoti({ message: '', enforceRead: false });
				dispatch(setLoading(false));
			}
		} catch (e) {
			dispatch(setLoading(false));
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			} else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			} else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}
	return (
		<DefaultLayout>
			<input
				type='file'
				id='faculties-import'
				className='hidden'
				onChange={(e) => {
					uploadFile(e);
				}}
			/>
			<div className='faculties page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<div>Quản lý khoa</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div className='actions flex items-center  justify-between'>
						<div
							className='actions--item'
							onClick={() => setModelShow(true)}>
							<Button
								label='Thêm Khoa'
								icon={<i className='fas fa-user-plus' />}
							/>
						</div>
						<div className='flex items-center'>
							<div className='actions--item' onClick={() => setModalShowAddNotification(true)}>
								<Button
									color={'bg-red-700'}
									hoverColor={'bg-red-900'}
									label='Thông báo sinh viên'
									icon={<i className='far fa-bell' />}
								/>
							</div>
							<div
								className='actions--item ml-2'
								onClick={(e) => exportFaculties()}>
								<Button
									label='Export'
									icon={<i className='fas fa-download' />}
									color='bg-green-700'
									hoverColor='bg-green-800'
								/>
							</div>
						</div>
					</div>
					<div>
						{
							faculties.length === 0 ?
								<div className='faculties--list flex flex-wrap -ml-4 -mb-4'
									style={{ justifyContent: "center", alignItems: "center" }}>
									<img src='/notFound_01.png'
										alt='Không tìm thấy dữ liệu'
										title='Không tìm thấy dữ liệu'
									/>
								</div> :
								<div className='faculties--list flex flex-wrap -ml-4 -mb-4'>
									{faculties.map((fa: any) => {
										return (
											<div
												key={fa.id}
												className='faculties--item ml-4 mb-4 font-bold flex
												items-center justify-center text-white'
												style={{
													backgroundColor: fa.colorCode,
												}}
												onClick={() => goClass(fa.id)}>
												{
													fa.code === '' || fa.code === null ? (<div className='name'>{fa.name}</div>) : <div className='name'>{fa.name}<br />({fa.code})</div>
												}
												<div className='actions flex justify-center'>
													<div
														className='delete actions--item'
														onClick={(e) =>
															deleteFaculties(e, fa.id)
														}>
														<i className='fas fa-trash' />
													</div>
													<div
														className='edit actions--item ml-4'
														onClick={(e) =>
															openUpdateFaculties(
																e,
																fa.id,
																fa.name,
																fa.colorCode,
																fa.code
															)
														}>
														<i className='fas fa-pen' />
													</div>
												</div>
											</div>
										);
									})}
								</div>
						}
						<ConfirmDialog
							confirmDialog={confirmDialog}
							setConfirmDialog={setConfirmDialog}
							deleteItem={deleteItem}
						/>
						<ConfirmDialog
							confirmDialog={confirmDialogAddNotification}
							setConfirmDialog={setConfirmDialogAddNotification}
							deleteItem={addNotification}
						/>
						<ErrorDialog
							errorDialog={errDialog}
							setErrorDialog={setErrDialog}
						/>
					</div>
					<Modal
						show={modalShow}
						aria-labelledby='contained-modal-title-vcenter'
						onHide={() => setModelShow(false)}
						centered>
						<Modal.Header closeButton>
							<Modal.Title id='contained-modal-title-vcenter'>
								{updateMode ? 'Cập nhật khoa' : 'Tạo mới khoa'}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='flex flex-nowrap'>
								<div className='w-9/12 mr-4'>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Tên Khoa
									</div>
									<input
										name='text'
										type='text'
										required
										className='appearance-none block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										placeholder='Nhập tên khoa'
										value={facultiesName}
										onChange={(e) => {
											setFacultiesName(e.target.value);
										}}
									/>
									<br />
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Mã khoa
									</div>
									<input
										name='text'
										type='text'
										required
										className='appearance-none block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										placeholder='Nhập mã khoa'
										value={facultiesCode}
										onChange={(e) => {
											setFacultiesCode(e.target.value);
										}}
									/>
								</div>
								<div className='w-3/12'>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Chọn màu nền
									</div>
									<input
										name='color'
										type='color'
										placeholder='Chọn màu săc'
										value={facultiesColor}
										onChange={(e) => {
											setFacultiesColor(e.target.value);
										}}
									/>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<div
								onClick={() => {
									updateMode
										? updateFaculties(updateMode)
										: createFaculties();
								}}>
								<Button
									label={updateMode ? 'Lưu lại' : 'Tạo mới'}
								/>
							</div>
						</Modal.Footer>
					</Modal>
					<Modal
						show={modalShowAddNotification}
						aria-labelledby='contained-modal-title-vcenter'
						onHide={() => {
							setModalShowAddNotification(false);
							setNoti({ message: '', enforceRead: false });
						}}
						centered>
						<Modal.Header closeButton>
							<Modal.Title id='contained-modal-title-vcenter'>
								<i className='far fa-bell' /> Thêm thông báo cho toàn bộ sinh viên
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
									<i className={'far fa-pencil'} /> Nội dung thông báo
								</div>
								<textarea
									name='text'
									maxLength={100}
									rows={5}
									required
									className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
									placeholder='Nhập nội dung thông báo'
									value={noti.message}
									onChange={(e) => {
										setNoti({ ...noti, message: e.target.value });
									}}
								/>
							</div>
							<div className='mt-2 flex center'>
								<div className='pl-1 text-sm font-semibold mb-2 text-gray-600'>
									Thông báo quan trọng
								</div>
								<input
									name={'enforceRead'}
									type='checkbox'
									className='h-4 w-4 mt-0.5 ml-4'
									onChange={(e) => {
										setNoti({
											...noti,
											// @ts-ignore
											enforceRead: e.target.checked
										});
									}}
									// @ts-ignore
									checked={noti.enforceRead}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<div onClick={() => {
								setModalShowAddNotification(false);
								setNoti({ message: '', enforceRead: false });
							}}>
								<Button
									label={'Hủy'}
									color={'bg-red-800'}
									hoverColor={'bg-red-900'}
									icon={<i className='far fa-times-circle' />}
								/>
							</div>
							<div
								onClick={() => {
									createNotification();
								}}>
								<Button
									color={'bg-green-800'}
									hoverColor={'bg-green-900'}
									icon={<i className={'far fa-bell'} />}
									label={'Tạo mới'}
								/>
							</div>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</DefaultLayout>
	);
}

export default Faculties;
