import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useParams } from 'react-router-dom';
import { $api } from 'services/service';
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Class__Create() {
	let history = useHistory();
	const query = useQuery();
	const dispatch = useDispatch();
	let faId: any = query.get('f');
	let { id } = useParams();
	const [updateMode, setUpdateMode] = useStateIfMounted(false);
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [faculty, setFaculty] = useStateIfMounted({
		name: '',
	});

	const [classDetail, setClassDetail] = useStateIfMounted({
		id: '',
		name: '',
		classCodeName: '',
		startDate: new Date(),
		facultyId: faId,
		isGraduated: false
	});

	const [classDetailTmp, setClassDetailTmp] = useStateIfMounted({
		id: '',
		name: '',
		classCodeName: '',
		startDate: new Date(),
		facultyId: faId,
		isGraduated: false
	});

	useEffect(() => {
		async function getClassDetail(id) {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.class.getDetail(id);
				if (res && res.result) {
					setUpdateMode(true);
					setClassDetail(res.result);
					let tmp = Object.assign({}, res.result);
					setClassDetailTmp(tmp);
					dispatch(setLoading(false));
				}
			} catch (e) {
				setUpdateMode(false);
				dispatch(setLoading(false));
			}
		}

		//
		async function getFaculty(id) {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.faculties.getDetail(id);
				setFaculty(res.result);
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}
		if (faId && faId?.length) {
			getFaculty(faId);
		}
		if (id && id !== 'them-moi') {
			getClassDetail(id);
		}
	}, [faId, id]);

	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}

	async function submit(e) {
		e.preventDefault();
		let res;
		try {
			if (classDetail.name === '') {
				show_ErrMsg('Vui lòng nhập tên lớp');
				return;
			}
			if (classDetail.classCodeName === '') {
				show_ErrMsg('Vui lòng nhập mã lớp');
				return;
			}
			if (!classDetail.facultyId || classDetail.facultyId.length <= 0) {
				// @ts-ignore
				classDetail.facultyId = faId;
			}
			dispatch(setLoading(true));
			if (updateMode) {
				res = await $api.class.updateClass(classDetail);
			} else {
				res = await $api.class.createClass(classDetail);
			}
			if (res && res.message) {
				dispatch(
					setAlert({
						title: res.message,
					})
				);
				history.push(`/lop-hoc?f=` + classDetail.facultyId);
				dispatch(setLoading(false));
			}
		} catch (e) {
			dispatch(setLoading(false));
			// let ErrMsg: string = e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function cancel() {
		setClassDetail(classDetailTmp);
		history.push(`/lop-hoc?f=` + faId);
	}

	return (
		<DefaultLayout>
			<div className='page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<div>
							{'Khoa ' +
								faculty?.name +
								' > ' +
								(updateMode
									? `Chi tiết lớp học: ${classDetail.name} (${classDetail.classCodeName})`
									: 'Thêm mới lớp học')}
						</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div className='max-450 py-4'>
						<div className='label-form font-bold'>
							Thông tin lớp học:
						</div>
						<div>
							<form
								className='mt-4 space-y-4 flex item-center flex-col justify-center'
								onSubmit={(e) => submit(e)}
								onKeyDown={(e) =>
									e.key === 'Enter' && submit(e)
								}>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Tên lớp học
									</div>
									<input
										name='text'
										type='text'
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập tên lớp học'
										value={classDetail.name || ''}
										onChange={(e) => {
											setClassDetail({
												...classDetail,
												name: e.target.value,
											});
										}}
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Mã lớp học
									</div>
									<input
										name='text'
										type='text'
										className='input-custom appearance-none block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										placeholder='Nhập mã lớp học'
										value={classDetail.classCodeName || ''}
										onChange={(e) => {
											setClassDetail({
												...classDetail,
												classCodeName: e.target.value,
											});
										}}
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Ngày bắt đầu
									</div>
									<DatePicker
										selected={
											new Date(classDetail.startDate)
										}
										onChange={(date) => {
											setClassDetail({
												...classDetail,
												startDate: date,
											});
										}}
										dateFormat='dd/MM/yyyy'
									/>
								</div>

								{
									updateMode === true ?
										<div className='mt-4 flex center'>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Đã tốt nghiệp:
											</div>
											<input
												type='checkbox'
												className='h-4 w-4 mt-0.5 ml-4'
												onChange={(e) => {
													setClassDetail({
														...classDetail,
														// @ts-ignore
														isGraduated: e.target.checked,
													});
												}}
												// @ts-ignore
												checked={classDetail.isGraduated}
											/>
										</div>
									: 
									<div></div> 
								}



								<div className='flex justify-center'>
									<button
										type='button'
										className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
										onClick={cancel}>
										Hủy Bỏ
									</button>
									<button
										type='submit'
										className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
										Lưu lại
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</DefaultLayout>
	);
}

export default Class__Create;
