import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { routes } from 'router/routes';
import { store } from 'app/store';
import 'assets/styles/main.scss';
import 'assets/styles/tailwindcss/index.css';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import moment from 'moment-timezone';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';

function App() {
    useEffect(() => {
        registerLocale('vi', vi);
        moment.tz.setDefault('Asia/Saigon');
    }, []);
    return (
        <React.StrictMode>
            <Provider store={store}>
                <Router>{renderRoutes(routes)}</Router>
            </Provider>
        </React.StrictMode>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
